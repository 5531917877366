//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyADYc7O_dV18wmEhsLYPNUJzAWFZjbrGz4",
  authDomain: "targetchains.firebaseapp.com",
  projectId: "targetchains",
  storageBucket: "targetchains.appspot.com",
  messagingSenderId: "97575385750",
  appId: "1:97575385750:web:69242b5dabab2efacf60b7",
  measurementId: "G-JN4F8KC47N"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
